import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { inject, Injectable, Injector } from '@angular/core';
import { Environment, NavigationService } from '@ev-portals/dp/frontend/shared/util';
import { catchError, Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  #environment = inject(Environment);
  #injector = inject(Injector);
  #navigationService = inject(NavigationService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // sameSite attributes work around (it is same in Azure, but not in local it is different)
    req = req.clone({
      withCredentials: this.#environment.environment === 'local' ? true : false,
    });

    // Here we check whether we're getting 401 or 403 and redirect to the Federation Service's Login Page.
    return next.handle(req).pipe(
      catchError((res: HttpErrorResponse) => {
        console.log(req.method, req.url, res.status, res.error);

        // Here we redirect to Forbidden Page, when 403 error was thrown from Backend Roles Guard with specific message.
        if (res.status == 403 && res.error.message === 'Forbidden resource') {
          console.warn('[AuthInterceptor] You are not authorized this ressource');
          this.#navigationService.navigateToForbiddenPage();
        } else if (
          // If we get 401 and the message is 'No valid JWT token provided' then we know that the token has expired.
          res.status == 401 &&
          res.message === 'No valid JWT token provided'
        ) {
          console.warn('[AuthInterceptor] Token expired, redirecting to login.');

          // Logout
          const authService = this.#injector.get(AuthenticationService);
          authService.logout().subscribe(authService.logoutHandler);
        }

        return throwError(() => res);
      }),
    );
  }
}
