import { inject } from '@angular/core';
import { CanActivateFn, UrlTree } from '@angular/router';
import { NavigationService } from '@ev-portals/dp/frontend/shared/util';
import { map, Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';

export function adminGuard(): CanActivateFn {
  return (): Observable<boolean | UrlTree> => {
    const navigationService = inject(NavigationService);
    return inject(AuthenticationService).isAdmin$.pipe(
      map(isAdmin => isAdmin || navigationService.getUrlTreeByPageName('error-403')),
    );
  };
}
