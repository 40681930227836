import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  AuthenticationApiService,
  UserBasicInfoDto,
  UserBasicInfoResponseDto,
  UserInformationApiService,
} from '@ev-portals/dp/frontend/shared/api-client';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  tap,
} from 'rxjs';

import { FederationService } from './federation.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  #authApiService = inject(AuthenticationApiService);
  #userInfoApiService = inject(UserInformationApiService);
  #federationService = inject(FederationService);
  // TODO: add later
  // private cookieService = inject(CoreCookieService);
  // private analyticsService = inject(AnalyticsService);
  // private globalDataService = inject(GlobalDataService);

  #_user$ = new ReplaySubject<UserBasicInfoDto | null>(1);
  // Save the current state of the user
  #user: UserBasicInfoDto | null | undefined = undefined;
  user$ = this.#_user$.asObservable().pipe(shareReplay(1));
  isAdmin$: Observable<boolean> = this.user$.pipe(map(this.isAdmin), distinctUntilChanged());

  isLoggedIn$: Observable<boolean> = this.user$.pipe(
    map(v => !!v),
    distinctUntilChanged(),
  );
  loginEvent$ = this.isLoggedIn$.pipe(filter(v => v));
  logoutSuccess$ = this.#federationService.logoutSuccess$;

  logoutHandler = {
    next: (): void => {
      this.#federationService.initLogout();
      this.#initLocalUser(null);
    },
    error: (err: Error): void => {
      console.log('Error: Logout Unsuccessful', err);
    },
  };

  constructor() {
    this.#loadUser().subscribe();
  }

  public isAdmin(user: UserBasicInfoDto | null): boolean {
    return user?.roles.includes('CUG_WCM_EVP_DISTRIBUTOR_ADMIN') ?? false;
  }

  patchProductSearchQueryString(queryString: string): Observable<UserBasicInfoResponseDto> {
    this.#patchLocalUser({ productSearchQueryString: queryString });

    return this.#userInfoApiService.patchUser({
      body: { productSearchQueryString: queryString },
    });
  }

  // TODO: add later
  // public patchTours(tours: ToursDto): Observable<UserBasicInfoResponseDto> {
  //   this.patchLocalUser({ tours });

  //   return this.userInfoService.patchUser({
  //     body: {
  //       tours,
  //     },
  //   });
  // }

  #loadUser(): Observable<UserBasicInfoResponseDto> {
    return this.#userInfoApiService.getBasicUserInfo().pipe(
      catchError((res: HttpErrorResponse) => {
        throw res;
      }),
      tap(res => this.#initLocalUser(res.user)),
    );
  }

  login(authorizationCode: string): Observable<UserBasicInfoDto> {
    // TODO: add later
    // this.analyticsService.trackEvent('auth', 'login');

    return this.#authApiService
      .login({
        body: { authorizationCode },
      })
      .pipe(
        tap(userInfo => {
          this.#initLocalUser(userInfo);
        }),
      );
  }

  /**
   * Logout can be triggered by
   *  - the user explicitly
   *  - or by the authInterceptor, if any request gives 401 back - except the /me endpoint, which is allowed to give 401 back
   */
  logout(): Observable<void> {
    // TODO: add later
    // this.analyticsService.trackEvent('auth', 'logout');
    // this.analyticsService.clearData();
    // this.globalDataService.clearGlobalData();

    return this.#authApiService.logout();
  }

  #patchLocalUser(partialUser: Partial<UserBasicInfoDto>): void {
    if (this.#user) {
      this.#updateLocalUser({
        ...this.#user,
        ...partialUser,
      });
    }
  }

  // Happens on login & logout
  #initLocalUser(user: UserBasicInfoDto | null): void {
    // TODO: add later
    // this.analyticsService.initCustomer(
    //   user?.sbuInformation.join(',') ?? undefined
    // );

    // this.globalDataService.patchGlobalData({ userEmail: user?.email });

    (window as any).globalData = {
      userEmail: user?.email ?? undefined,
    };

    this.#updateLocalUser(user);
  }

  #updateLocalUser(user: UserBasicInfoDto | null): void {
    this.#_user$.next(user);
    this.#user = user;
  }
}
