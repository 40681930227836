import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavigationService } from '@ev-portals/dp/frontend/shared/util';
import { map, Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';

@Injectable({
  providedIn: 'root',
})
export class UserGuard {
  #authService = inject(AuthenticationService);
  #router = inject(Router);
  #navigationService = inject(NavigationService);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.#checkUser(state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.#checkUser(state);
  }

  #checkUser(state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.#authService.user$.pipe(
      // TODO: after we checked, that we have a user, we have to check, whether the user have locations available

      map(user => {
        if (!user) {
          // save current url in localStorage to redirect to it after login
          this.#navigationService.storeOriginalDestination(state.url);

          return this.#router.parseUrl(this.#navigationService.getUrlByPageName('login'));
        }

        // TODO: add later
        // if (!user?.roles.length) {
        //   return this.router.parseUrl(
        //     this.navigationService.getUrlByPageName('error-no-role-assigned')
        //   );
        // }

        return true;
      }),
    );
  }
}
